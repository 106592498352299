import React from "react";
import "./projects.css";
import aika from "../../assets/videos/aika.mp4";
import ensar from "../../assets/videos/ensar.mp4";
import era from "../../assets/images/era.png";
import { useTranslation } from "react-i18next";

const Projects = () => {
  const { t } = useTranslation();

  return (
    <div id="projects" className="projects">
      {t("leng") === "en" ? (
        <h2>
          our
          <span> projects</span>
        </h2>
      ) : (
        <h2>
          наши
          <span> проекты</span>
        </h2>
      )}
      <div className="wrapper">
        <div className="column">
          <div data-aos="zoom-in-up" className="video_block">
            {/* <a
              href="https://aika.kg/"
              target="_blank"
              rel="noopener noreferrer"
            > */}
            <video className="video" src={aika} autoPlay loop muted />
            {/* </a> */}
          </div>

          {/* <div data-aos="zoom-in-up" className="video_block">
            <a
              href="https://ensarcargo.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
            <img src={aist} alt="" className="img" />
            </a>
          </div> */}
        </div>
        <div className="column center">
          <div data-aos="zoom-in-up" className="video_block">
            <a
              href="https://era-travel.kg/ru"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={era} alt="" className="img" />
            </a>
          </div>
          {/* <div data-aos="zoom-in-up" className="video_block">
            <a
              href="https://skyfru.kg/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <video className="video" src={skyfru} autoPlay loop muted />
            </a>
          </div> */}
        </div>
        <div className="column">
          <div data-aos="zoom-in-up" className="video_block">
            {/* <a
              href="https://ensarcargo.com/"
              target="_blank"
              rel="noopener noreferrer"
            > */}
            <video className="video" src={ensar} autoPlay loop muted />
            {/* </a> */}
          </div>
          {/* <div data-aos="zoom-in-up" className="video_block dowload play">
            <a
              href="https://play.google.com/store/apps/details?id=com.bekaserkan.Bitok"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={bitok} alt="" className="img" />
            </a>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default Projects;
